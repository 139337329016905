import React, { lazy } from 'react';

const NewModel = lazy(() => import('./NewModel'));

export const NewModelRoutes = [
  {
    path: '/create',
    element: <NewModel />,
    breadcrumbs: [],
  },
];

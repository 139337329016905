import axios from 'axios';
import { createBrowserHistory } from 'history';
import { getAccessToken, isValidToken } from '../../utils/token/token';
import axiosCache from './axiosCache';

const history = createBrowserHistory();

const baseURL = 'https://api.databridgeai.com';

const axiosInstance = axios.create({
  baseURL,
  adapter: axiosCache.defaults.adapter,
});

axiosInstance.defaults.headers['content-type'] = 'application/json';
const accessToken = getAccessToken();

axiosInstance.interceptors.request.use((req) => {
  if (isValidToken(accessToken)) {
    return req;
  }

  const pathname = history.location.pathname;
  if (pathname === '/auth') {
    return req;
  }
  history.push({
    pathname: '/auth',
    state: { sessionExpired: true },
  });
  return req;
});

axiosInstance.defaults.headers.common.Authorization = `Bearer ${getAccessToken()}`;

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || {
        message: 'Something went wrong!',
        error,
      }
    )
);

export default axiosInstance;

import React, { lazy } from 'react';

const Dashboard = lazy(() => import('./Dashboard'));

export const DashboardRoutes = [
  {
    path: '/dashboard',
    element: <Dashboard />,
    breadcrumbs: [],
  },
];

import React from 'react';
import AuthForm from './forms/AuthForm';
import loginpic from '../../assets/images/Left side.png';
import './authpage.scss';
import { GenieSvg } from '../../assets/assets';
import { Button } from '@mui/material';
import Modal from './../../components/dialog/Modal';
import TalkToGenieForm from './forms/TalkToGenieForm';
const Auth = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <div className="auth-page">
      <div className="auth-page-col1">
        <img alt="" loading="lazy" src={loginpic} height="100%" width="100%" />
      </div>
      <div className="forms">
        {/* <Button
          className="dialog-button"
          variant="contained"
          onClick={() => setOpen(true)}
        >
          <GenieSvg size={'small'} color={'white'} />
          Talk to Genie
        </Button> */}
        <AuthForm />
      </div>
      {open && (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          width={500}
          height={500}
        >
          <TalkToGenieForm />
        </Modal>
      )}
    </div>
  );
};

export default Auth;

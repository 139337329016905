import React, { createContext, useContext, useState } from 'react';

const ButtonVisibilityContext = createContext();

export const useButtonVisibility = () => {
  return useContext(ButtonVisibilityContext);
};

export const ButtonVisibilityProvider = ({ children }) => {
  const [showCreateModelButton, setShowCreateModelButton] = useState(true);

  return (
    <ButtonVisibilityContext.Provider
      value={{ showCreateModelButton, setShowCreateModelButton }}
    >
      {children}
    </ButtonVisibilityContext.Provider>
  );
};

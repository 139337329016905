import React, { lazy } from 'react';

const MakePredictions = lazy(() => import('./MakePredictions'));

export const MakePredictionsRoutes = [
  {
    path: '/Predictions',
    element: <MakePredictions />,
    breadcrumbs: [],
  },
];

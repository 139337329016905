import React, { lazy } from 'react';

const ModelVersion = lazy(() => import('./ModelVersion'));
const ModelVersionDetails = lazy(() => import('./ModelVersionDetails'));

export const modelVersionRoutes = [
  {
    path: '/models',
    element: <ModelVersion />,
    breadcrumbs: [],
  },
  {
    path: '/models/:id',
    element: <ModelVersionDetails />,
    breadcrumbs: [],
  },
];

import React, { lazy } from 'react';

const SampleDatasets = lazy(() => import('./SampleDatasets'));

export const SampleDatasetsRoutes = [
  {
    path: '/sampleDatasets',
    element: <SampleDatasets />,
    breadcrumbs: [],
  },
];

import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Sidebarimage from '../../assets/images/databridgelogoimage.png';
import { SidebarPanel } from './SideBarpanel';
import {
  Avatar,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import useAuth from '../../config/hooks/useAuth';
import { LogoutOutlined } from '@mui/icons-material';
import { SignOutSvg } from '../../assets/assets';

const Sidebar = ({ isCollapsed, toggleSidebar }) => {
  const { user, logout } = useAuth();
  const location = useLocation();

  const handleLogout = () => {
    logout();
  };

  return (
    <div
      style={{
        backgroundColor: isCollapsed ? '#344054' : 'white',
        height: '100%',
        margin: 0,
        padding: 0,
      }}
    >
      <div className="logo-button">
        {isCollapsed ? null : (
          <img
            src={Sidebarimage}
            alt="logo"
            style={{ width: '80%', height: '100%' }}
          />
        )}
        <div className="collapse-icon" onClick={toggleSidebar}>
          {isCollapsed ? null : (
            <ArrowBackIosOutlinedIcon
              fontSize="small"
              sx={{
                backgroundColor: '#344054',
                color: 'white',
                borderRadius: '50%',
              }}
            />
          )}
        </div>
      </div>
      {isCollapsed ? (
        <div className="sidebarCollapsedIcon">
          {SidebarPanel.map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              data-tooltip-id={item.key}
              // title={item.name}
            >
              {item.Icon && <item.Icon color="white" fillOpacity="1" />}
              <ReactTooltip
                id={item?.key}
                place="right"
                content={item?.name}
                style={{
                  backgroundColor: '#344054',
                  zIndex: 23,
                  fontSize: '10px',
                  padding: '5px',
                  marginLeft: '5px',
                }}
              />
            </NavLink>
          ))}
        </div>
      ) : (
        <div style={{ marginTop: '5px' }}>
          {SidebarPanel.map((item, index) => (
            <li
              key={index}
              className={`sidebar-link ${
                location.pathname === item.path ? 'active-link' : ''
              }`}
            >
              {item.isSeparator ? (
                <div className="separator-label">{item.name}</div>
              ) : (
                <NavLink to={item.path} className="sidebar-link">
                  <item.Icon />
                  <div
                    className={`item-name ${
                      location.pathname === item.path ? 'active-item' : ''
                    }`}
                    style={{
                      marginTop: '2px',
                    }}
                  >
                    {item.name}
                  </div>
                </NavLink>
              )}
            </li>
          ))}
        </div>
      )}
      {isCollapsed ? null : (
        <div className="logout-class">
          <Avatar alt={user?.name[0][1]} src="/"></Avatar>
          <div style={{ paddingTop: '5px' }}>
            <Typography
              sx={{
                fontSize: '12px',
                padding: '0px 10px',
                color: '#101928',
                fontWeight: 600,
              }}
            >
              {user.name}
            </Typography>
            <Typography
              sx={{
                fontSize: '12px',
                padding: '0px 10px',
                color: '#344054',
                fontWeight: 400,
              }}
            >
              {user.email}
            </Typography>
          </div>
          <SignOutSvg style={{ marginTop: '10px' }} onClick={handleLogout} />
        </div>
      )}
    </div>
  );
};

export default Sidebar;

import {
  HomeLogo,
  MakePredictionsLogo,
  ModelVersionLogo,
  CreateNewModelLogo,
  SampleDataSetsLogo,
  SupportLogo,
  SettingsLogo,
  BillingLogo,
  ApiTokenLogo,
  SampleCurlsLogo,
  HistorySvg,
  GenieLogo,
  GenieSvg,
  PredictionHistorySvg,
} from '../../assets/assets';

export const SidebarPanel = [
  {
    Icon: GenieLogo,
    name: 'Talk to Genie',
    path: '/genie',
    key: 'genie',
    children: [],
  },
  {
    Icon: HomeLogo,
    name: 'Dashboard',
    path: '/dashboard',
    key: 'dashboard',
    children: [],
  },
  {
    Icon: CreateNewModelLogo,
    name: 'Create New Model',
    path: '/create',
    key: 'newModel',
  },
  {
    Icon: ModelVersionLogo,
    name: 'Model Versions',
    path: '/models',
    key: 'modelVersions',
  },
  {
    Icon: MakePredictionsLogo,
    name: 'Make Predictions',
    path: '/Predictions',
    key: 'predictions',
  },
  {
    Icon: PredictionHistorySvg,
    name: 'Prediction History',
    path: '/history',
    key: 'history',
  },

  {
    isSeparator: true,
    name: 'SETTINGS',
  },
  {
    Icon: BillingLogo,
    name: 'Billing',
    path: '/billing',
    key: 'billing',
  },

  {
    isSeparator: true,
    name: 'DEVELOPERS',
  },
  {
    Icon: ApiTokenLogo,
    name: 'API Token',
    path: '/apiToken',
    key: 'apiToken',
  },
  {
    Icon: SampleCurlsLogo,
    name: 'Sample Curls',
    path: '/sampleCurls',
    key: 'sampleCurls',
  },
];

import React from 'react';
import Navigation from './router/Navigation';
import './utils/toast/toast.css';
import 'react-toastify/dist/ReactToastify.css';
import 'rodal/lib/rodal.css';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Navigation />
    </>
  );
}

export default App;

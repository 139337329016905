import { LogoutOutlined } from '@mui/icons-material';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import {
  Avatar,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useButtonVisibility } from '../../config/context/ButtonVisisbilityContext';
import useAuth from '../../config/hooks/useAuth';
import { CreateNewModelLogo, NotificationLogo } from '../../assets/assets';

const Navbar = ({ isCollapsed, toggleSidebar }) => {
  const { showCreateModelButton } = useButtonVisibility();
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleCreateNewButton = () => {
    navigate(`/create`);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="navbar-children">
      <div
        className={`collapse-icon ${isCollapsed ? 'collapsed-icon' : ''}`}
        onClick={toggleSidebar}
      >
        {isCollapsed ? (
          <ArrowForwardIosOutlinedIcon
            fontSize="small"
            sx={{
              backgroundColor: '#344054',
              color: 'white',
              borderRadius: '50%',
            }}
          />
        ) : null}
      </div>
      <div className="navbar-content">
        {showCreateModelButton && (
          <Button
            variant="contained"
            sx={{
              fontSize: 'small',
              marginBottom: '10px',
            }}
            onClick={handleCreateNewButton}
          >
            <CreateNewModelLogo color={'white'} />{' '}
            <p style={{ paddingLeft: '5px', color: 'white' }}>
              Create new Model
            </p>
          </Button>
        )}

        <FormControl variant="standard" value={user.name}>
          <Select
            value={user.name}
            sx={{
              borderRadius: '0.125rem',
              p: '0.15rem 0.1rem',
              '& .MuiSvgIcon-root': {
                display: 'none',
              },
            }}
            renderValue={(value) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <Avatar alt={user?.name} src="/">
                  {user?.name[0] + user.name[1]}
                </Avatar>
                {/* <Typography sx={{ marginLeft: '10px' }}>{value}</Typography> */}
              </div>
            )}
          >
            <MenuItem>
              <Avatar alt={user?.name} src="/">
                {user?.name[0] + user.name[1]}
              </Avatar>
              <div style={{ margin: '0 10px' }}>
                <Typography>{user.name}</Typography>
                <Typography>{user?.email}</Typography>
              </div>
              <LogoutOutlined onClick={handleLogout} sx={{ margin: '0 5px' }} />
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default Navbar;

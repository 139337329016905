import React, { lazy } from 'react';

const Genie = lazy(() => import('./Genie.jsx'));

const headPath = '/dashboard';
const baseCrumbs = [
  { name: 'Dashboard' },
  // { name: 'Settings', link: '/settings' },
  { name: 'Genie', link: '/genie' },
];
export const GenieRoutes = [
  {
    path: '/genie',
    element: <Genie />,
    // breadcrumbs: [...baseCrumbs],
  },
];

import React, { lazy } from 'react';

const Billing = lazy(() => import('./Billing'));

export const BillingRoutes = [
  {
    path: '/billing',
    element: <Billing />,
    breadcrumbs: [],
  },
];

import React, { lazy } from 'react';

const SampleCurls = lazy(() => import('./SampleCurls'));

export const SampleCurlsRoutes = [
  {
    path: '/sampleCurls',
    element: <SampleCurls />,
    breadcrumbs: [],
  },
];

import React, { lazy } from 'react';

const PredictionHistory = lazy(() => import('./Prediction'));
const PredictionHistoryDetails = lazy(() =>
  import('./PredictionHistoryDetails')
);

export const PredictionHistoryRoutes = [
  {
    path: '/history',
    element: <PredictionHistory />,
    breadcrumbs: [],
  },
  {
    path: '/history/:id',
    element: <PredictionHistoryDetails />,
    breadcrumbs: [],
  },
];

import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import useAuth from '../../config/hooks/useAuth';
import NotFound from '../../error/404';
import Layout from '../../layout/Layout';
import { authConfigRoutes, authorizedRoutes } from './routeConfig';
import { CircularProgress } from '@mui/material';

const RouteWrapper = () => {
  const { isAuthenticated } = useAuth();
  const displayElement = ({ element, layout, breadcrumbs, showAds }) => {
    return (
      <Layout {...layout} breadcrumb={breadcrumbs} showAds={showAds}>
        <Suspense
          fallback={
            <CircularProgress
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          }
        >
          {element}
        </Suspense>
      </Layout>
    );
  };

  return (
    <Routes>
      {isAuthenticated ? (
        <Route
          key="auth-redirect"
          path="*"
          element={<Navigate to="/dashboard" />}
        />
      ) : (
        authConfigRoutes.map((route, index) => (
          <Route
            key={`auth-route-${index}`}
            path={route.path}
            element={route.element}
          />
        ))
      )}

      {isAuthenticated &&
        authorizedRoutes.map((route, index) => (
          <Route
            key={`authorized-route-${index}`}
            path={route.path}
            element={displayElement(route)}
          />
        ))}
      {isAuthenticated && (
        <Route key="not-found" path="*" element={<NotFound />} />
      )}
      {!isAuthenticated && (
        <Route key="auth-redirect" path="*" element={<Navigate to="/auth" />} />
      )}
    </Routes>
  );
};

export default RouteWrapper;

import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('The Email is required'),
  password: Yup.string().required('Password is Required'),
});

export const RegisterSchema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('The Email is required'),
  password: Yup.string().required('Password is Required'),
});

import React, { lazy } from 'react';

const Support = lazy(() => import('./Support'));

export const SupportRoutes = [
  {
    path: '/support',
    element: <Support />,
    breadcrumbs: [],
  },
];

import React, { lazy } from 'react';

const ApiToken = lazy(() => import('./ApiTokens'));

export const ApiTokenRoutes = [
  {
    path: '/apiToken',
    element: <ApiToken />,
    breadcrumbs: [],
  },
];

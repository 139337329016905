import { ArrowCircleLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from './footer/Footer';
import Navbar from './navbar/Navbar';
import Sidebar from './sidebar/Sidebar';

const Layout = ({ children, breadcrumb }) => {
  const navigate = useNavigate();
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);

  const toggleSidebar = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };

  const isMobileDevice = () => {
    return window.innerWidth <= 768;
  };

  useEffect(() => {
    setSidebarCollapsed(isMobileDevice());
  }, []);

  return (
    <div className="layout">
      <div className={`side ${isSidebarCollapsed ? 'collapsed' : ''}`}>
        <Sidebar
          isCollapsed={isSidebarCollapsed}
          toggleSidebar={toggleSidebar}
        />
      </div>

      <div className={`main ${isSidebarCollapsed ? 'mainFullWrap' : ''}`}>
        <div className="nav">
          <Navbar
            isCollapsed={isSidebarCollapsed}
            toggleSidebar={toggleSidebar}
          />
        </div>
        <div className="child">
          {breadcrumb?.length == 0 ? null : breadcrumb ? (
            <div className="breadcrumb">
              <div className="goBackBtn">
                <ArrowCircleLeft onClick={() => navigate(-1)} />
              </div>
              {breadcrumb.map((d, i) => {
                return (
                  <div className="single">
                    <Typography
                      onClick={() => navigate(d?.link)}
                      variant={'p'}
                      key={i}
                      sx={
                        i + 1 == breadcrumb.length
                          ? {
                              fontWeight: '500',
                              // textDecoration: 'underline',
                            }
                          : {
                              color: '#656a71',
                            }
                      }
                    >
                      {d?.name}
                    </Typography>

                    {i + 1 == breadcrumb.length ? null : (
                      <span>
                        <KeyboardArrowRight />
                      </span>
                    )}
                  </div>
                );
              })}
            </div>
          ) : null}
          {children}
        </div>
        <div className="foot">
          {' '}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Layout;

import { Navigate } from 'react-router-dom';
import Nopermission from '../../error/401';
import { ApiTokenRoutes } from '../../pages/ApiTokens/ApiTokenRoute';
import { authRoutes } from '../../pages/authPage/authRoutes';
import { BillingRoutes } from '../../pages/billing/BillingRoute';
import { DashboardRoutes } from '../../pages/dashboard/dashboardRoutes';
import { MakePredictionsRoutes } from '../../pages/makePredictions/MakePredictionRoutes';
import { modelVersionRoutes } from '../../pages/modelVersion/modelversionRoute';
import { NewModelRoutes } from '../../pages/newModel/newModelRoute';
import { SampleCurlsRoutes } from '../../pages/sampleCurls/SampleCurlsRoute';
import { SampleDatasetsRoutes } from '../../pages/sampleDataSets/SampleDatasetRoutes';
import { SettingsRoutes } from '../../pages/settings/SettingsRoute';
import { SupportRoutes } from '../../pages/support/supportRoute';
import { PredictionHistoryRoutes } from '../../pages/predictionHistory/predictionHistoryRoute';
import { GenieRoutes } from '../../pages/genie/genieRoute';

export const authConfigRoutes = [
  ...authRoutes,

  {
    path: '/401',
    element: <Nopermission />,
  },
];

export const authorizedRoutes = [
  {
    path: '/',
    element: [<Navigate to="/dashboard" />],
  },
  ...GenieRoutes,
  ...DashboardRoutes,
  ...NewModelRoutes,
  ...modelVersionRoutes,
  ...ApiTokenRoutes,
  ...BillingRoutes,
  ...MakePredictionsRoutes,
  ...PredictionHistoryRoutes,
  ...SampleCurlsRoutes,
  ...SampleDatasetsRoutes,
  ...SettingsRoutes,
  ...SupportRoutes,
];

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from '../config/context/JWTAuthContext';
import { ButtonVisibilityProvider } from '../config/context/ButtonVisisbilityContext';
import RouteWrapper from './routerConfigs/RouteWrapper';

const Navigation = () => {
  return (
    <>
      <Router>
        <AuthProvider>
          <ButtonVisibilityProvider>
            <RouteWrapper />
          </ButtonVisibilityProvider>
        </AuthProvider>
      </Router>
    </>
  );
};

export default Navigation;

import { createTheme } from '@mui/material/styles';
const theme = createTheme({
  palette: {
    primary: {
      main: '#282F75',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#32475C99',
    },
    text: {
      primary: '#32475CDE',
      secondary: '#32475C99',
      disabled: '#32475C61',
    },
    info: {
      main: '#21AEDB',
    },
    error: {
      light: '#FF5B3F',
      main: '#FF3E1D',
    },
    other: {
      ratingActive: '#FDB528',
    },
    background: {
      secondary: '#30334EE0',
    },
  },
  typography: {
    fontFamily: 'Mena Grotesk, sans-serif',
    h4: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    body1: {
      lineHeight: '1.5',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          backgroundColor: '#282F75',
          color: 'white',
          textTransform: 'none',
          borderRadius: '5px',
          // '&:hover': {
          //   backgroundColor: (theme) => darken(theme.palette.primary.main, 5.2),
          // },
        },
        contained: {},
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#32475CDE',
        },
      },
    },
    MuiBox: {
      styleOverrides: {
        root: {
          textAlign: 'left',
          padding: '10px',
          margin: '10px',
          width: '100%',
        },
      },
    },
  },
});

export default theme;
